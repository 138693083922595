import { faChevronDown, faChevronUp, faPlus, faTrashCan } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { codeableConceptAsString, Coding, Medication, MedicationBatch } from "fhir"
import { FieldArray, FieldArrayRenderProps, FieldProps, useFormikContext } from "formik"
import { Dropdown } from "primereact/dropdown"
import { useMountEffect } from "primereact/hooks"
import { InputNumber } from "primereact/inputnumber"
import { classNames } from "primereact/utils"
import { FC, useState } from "react"

import { Button, DateField, DropdownField, FormField, InputField, RoundedStyles, ValueSetIds } from "commons"
import { formatsByTypes } from "data"
import { formatDate } from "utils"
import { useValueSet } from "value-set"

import { bodyZonesCodes, InventoryData, PROCEDURE_CONFIG, ProcedureData } from "../../types"
import { BodySitesAndDosageField } from "./BodySitesAndDosageField"

const MedConfigurationItem: FC<Props> = ({
  medIndex,
  expanded,
  isSingleBodySiteSelection,
  configCodes,
  bodySiteActionCode,
  onClick,
  onChangeBodySite,
  onChangeBodyZone,
  handelUpdateDosage,
}) => {
  const { codes: bodySites } = useValueSet({ valueSetId: ValueSetIds.BODY_SITES })
  const [maxQuantityByBatch, setMaxQuantityByBatch] = useState<Record<string, number>>({})
  const [quantityByLot, setQuantityByLot] = useState<Record<string, number>>({})

  const {
    values: { configurationItem },
    setFieldValue,
  } = useFormikContext<ProcedureData>()

  const medLot = (item: InventoryData) =>
    item ? (
      <div className="flex gap-2">
        <span>L: {item.lotNumber}</span>
        <span>
          E: {item.expirationDate ? formatDate(new Date(item.expirationDate), formatsByTypes.ISO_8601_DATE) : "N/A"}
        </span>
        <span>Q: {item.quantity}</span>
      </div>
    ) : undefined

  const requireMedAdministration = configCodes.includes(PROCEDURE_CONFIG.PELLET)
  const requiresDoseConfiguration = configCodes.includes(PROCEDURE_CONFIG.BODY_SITE_COORDINATE_AND_DOSAGE)

  const dosage = configurationItem[medIndex]?.bodySite?.coding?.find(
    ({ system }) => system === `${bodySiteActionCode?.system}/configure-dosage`,
  )

  const cardTitle = !requireMedAdministration
    ? `Massage ${medIndex + 1}`
    : codeableConceptAsString(configurationItem[medIndex]?.medicationRequest?.medication?.CodeableConcept)

  const onQtyChange = (value: number, batchIndex?: number, lotNumber?: string, lotQuantity?: number) => {
    const meds = (configurationItem[medIndex]?.medicationAdministration?.contained as Medication[]) ?? []
    const updatedMeds = [...meds, ...(meds.length === batchIndex ? [{}] : [])]

    const { totalQty, quantityByLot } = updatedMeds.reduce<{ totalQty: number; quantityByLot: Record<string, number> }>(
      (acc, med, index) => {
        const lot = index === batchIndex && lotNumber ? lotNumber : (med?.batch?.lotNumber as string)
        const qty = index === batchIndex ? value : med?.amount?.numerator?.value ?? 1

        return {
          totalQty: acc.totalQty + qty,
          quantityByLot: !lot
            ? acc.quantityByLot
            : { ...acc.quantityByLot, [lot]: (acc.quantityByLot[lot] ?? 0) + qty },
        }
      },
      { totalQty: 0, quantityByLot: {} },
    )

    const { maxQuantity, updatedQty } = updatedMeds.reduce<{
      maxQuantity: Record<number, number>
      updatedQty?: number
    }>(
      (acc, med, index) => {
        const lot = index === batchIndex && lotNumber ? lotNumber : (med?.batch?.lotNumber as string)
        const qty = index === batchIndex ? value : med?.amount?.numerator?.value ?? 1
        const max = (index === batchIndex ? lotQuantity : undefined) ?? (med?.batch as InventoryData)?.quantity
        const available = max && max - quantityByLot[lot]
        const updatedQty = qty + (index === batchIndex ? Math.min(0, available ?? 0) : 0)
        const batchMax = max && Math.max(Math.min(max - quantityByLot[lot] + qty, max), updatedQty)
        return {
          updatedQty: updatedQty !== qty ? updatedQty : acc.updatedQty,
          maxQuantity: !max || !lot ? acc.maxQuantity : { ...acc.maxQuantity, [index]: batchMax },
        }
      },
      { maxQuantity: {} },
    )

    const dose = totalQty * (configurationItem[medIndex]?.medBaseDose?.value ?? 1)
    setFieldValue(`configurationItem[${medIndex}].medicationRequest.dispenseRequest.quantity.value`, totalQty)
    setFieldValue(
      `configurationItem[${medIndex}].medicationRequest.dispenseRequest.initialFill.quantity.value`,
      totalQty,
    )
    setFieldValue(`configurationItem[${medIndex}].medicationAdministration.dosage.dose.value`, dose)
    setFieldValue(`configurationItem[${medIndex}].medTotalDose.value`, dose)
    updatedQty &&
      setFieldValue(
        `configurationItem[${medIndex}].medicationAdministration.contained[${batchIndex}].amount.numerator.value`,
        updatedQty,
      )
    setMaxQuantityByBatch(maxQuantity)
    setQuantityByLot(quantityByLot)
  }

  useMountEffect(() => onQtyChange(0))

  return (
    <div
      className={classNames(
        "flex flex-col p-4 rounded-md border transition-all ease-in-out duration-200 min-w-96",
        expanded ? "cursor-default border-gray-700" : "cursor-pointer border-gray-300",
      )}
      onClick={onClick}
    >
      <div className="flex flex-row justify-between items-baseline space-x-1">
        <div className="flex flex-row gap-2 items-baseline">
          <span
            className={classNames(
              "rounded-full w-2 h-2",
              configurationItem[medIndex]?.bodySite?.coding?.[0]?.code ? "bg-green-500" : "bg-yellow-500",
            )}
          />
          <span className="font-medium text-gray-900">{cardTitle}</span>
        </div>
        <FontAwesomeIcon icon={expanded ? faChevronUp : faChevronDown} />
      </div>
      <div className={classNames("flex flex-col gap-4 p-4", { hidden: !expanded })}>
        {requireMedAdministration && (
          <FieldArray name={`configurationItem[${medIndex}].medicationAdministration.contained`}>
            {({ name, push, remove, form: { getFieldMeta } }: FieldArrayRenderProps) => {
              const fieldValue = getFieldMeta<Medication[]>(name).value

              const addBatch = () => {
                onQtyChange(1, fieldValue.length)
                if (isSingleBodySiteSelection) {
                  onPelletChange(fieldValue.length)
                  setFieldValue(`configurationItem[${medIndex}].bodySite.coding`, [
                    ...(configurationItem[medIndex].bodySite?.coding ?? []),
                    {},
                  ])
                }
                push({
                  batch: { lotNumber: undefined, expirationDate: undefined },
                  amount: { numerator: { value: 1 } },
                  code: configurationItem[medIndex]?.medicationRequest?.medication?.CodeableConcept,
                  resourceType: "Medication",
                })
              }

              const removeBatch = (batchIndex: number) => {
                onQtyChange(0, batchIndex)
                if (isSingleBodySiteSelection) {
                  onPelletChange(batchIndex)
                  const codes = configurationItem[medIndex].bodySite?.coding?.reduce<Coding[]>(
                    (acc, coding, index) => (index === batchIndex ? acc : [...acc, coding]),
                    [],
                  )
                  setFieldValue(`configurationItem[${medIndex}].bodySite.coding`, codes)
                }
                remove(batchIndex)
              }

              const onPelletChange = (batchIndex: number, siteCoding?: Coding) => {
                const sitesCodes = configurationItem[medIndex].bodySite?.coding?.reduce<string[]>(
                  (acc, { code }, index) =>
                    index === batchIndex
                      ? [...acc, ...(siteCoding?.code ? [siteCoding.code] : [])]
                      : [...acc, code as string],
                  [],
                ) ?? [siteCoding?.code as string]

                setFieldValue(
                  `configurationItem[${medIndex}].bodySite.text`,
                  sitesCodes.length > 1
                    ? `${sitesCodes.length} points selected`
                    : configurationItem[medIndex]?.bodySite?.coding?.[0]?.display ?? siteCoding?.display,
                )
                onChangeBodySite(batchIndex === fieldValue.length ? [...sitesCodes, ""] : sitesCodes)
              }

              return fieldValue.map((_, batchIndex) => {
                const availableBatches = configurationItem[medIndex]?.invData?.reduce<InventoryData[]>(
                  (acc, inv) =>
                    !!quantityByLot[inv.lotNumber] &&
                    inv.quantity <= quantityByLot[inv.lotNumber] &&
                    fieldValue[batchIndex].batch?.lotNumber !== inv.lotNumber
                      ? acc
                      : [...acc, inv],
                  [],
                )

                return (
                  <div key={batchIndex} className="flex gap-2 mt-2 first:mt-0">
                    <div className="flex flex-col flex-1 -space-y-2 border rounded-md pt-1 px-1.5 relative">
                      {configurationItem[medIndex]?.invData ? (
                        <FormField
                          field={`configurationItem[${medIndex}].medicationAdministration.contained[${batchIndex}].batch`}
                          label="Batch"
                          validation={(value: MedicationBatch) => (!value || !value.lotNumber) && "Batch is required"}
                        >
                          {({ field: { name, value, onChange } }: FieldProps) => (
                            <Dropdown
                              id={name}
                              name={name}
                              className="slashed small-trigger"
                              options={availableBatches}
                              optionLabel="lotNumber"
                              placeholder="Select a batch"
                              value={value}
                              onChange={(e) => {
                                onQtyChange(
                                  fieldValue[batchIndex]?.amount?.numerator?.value ?? 0,
                                  batchIndex,
                                  e.value?.lotNumber,
                                  e.value?.quantity,
                                )
                                onChange(e)
                              }}
                              itemTemplate={medLot}
                              valueTemplate={medLot}
                            />
                          )}
                        </FormField>
                      ) : (
                        <>
                          <InputField
                            label="Batch"
                            field={`configurationItem[${medIndex}].medicationAdministration.contained[${batchIndex}].batch.lotNumber`}
                            validation={(value) => !value && "Batch is required"}
                          />
                          <DateField
                            label="Expiration date"
                            field={`configurationItem[${medIndex}].medicationAdministration.contained[${batchIndex}].batch.expirationDate`}
                            stringFormatType="ISO_8601_DATETIME"
                            validation={(value) => !value && "Expiration date is required"}
                            minDate={new Date()}
                          />
                        </>
                      )}

                      <div className="flex gap-2">
                        <FormField
                          label="Quantity"
                          field={`configurationItem[${medIndex}].medicationAdministration.contained[${batchIndex}].amount.numerator.value`}
                          className={classNames({ "flex-1": !isSingleBodySiteSelection })}
                        >
                          {({ field: { name, value, onChange } }: FieldProps) => (
                            <InputNumber
                              id={name}
                              name={name}
                              locale="en-US"
                              showButtons
                              min={1}
                              max={
                                maxQuantityByBatch[batchIndex] ??
                                (fieldValue[batchIndex]?.batch as InventoryData)?.quantity
                              }
                              allowEmpty={false}
                              value={value}
                              onValueChange={onChange}
                              className="input-text-center p-inputtext-sm"
                              inputClassName="w-16"
                              onBlur={() => onQtyChange(value, batchIndex)}
                              title={`${
                                maxQuantityByBatch[batchIndex] ??
                                (fieldValue[batchIndex]?.batch as InventoryData)?.quantity
                              }`}
                            />
                          )}
                        </FormField>
                        {isSingleBodySiteSelection && (
                          <FormField
                            field={`configurationItem[${medIndex}].bodySite.coding[${batchIndex}]`}
                            label="Administration site"
                            className="flex-1"
                            validation={(value: Coding) => (!value || !value.code) && "Administration site is required"}
                          >
                            {({ field: { name, value, onChange } }: FieldProps) => (
                              <Dropdown
                                id={name}
                                name={name}
                                className="p-inputtext-sm"
                                options={bodySites}
                                optionLabel="display"
                                value={value}
                                onChange={(e) => {
                                  onChange(e)
                                  onPelletChange(batchIndex, e.value)
                                }}
                              />
                            )}
                          </FormField>
                        )}
                      </div>
                      <div className="flex items-center justify-center absolute top-3.5 right-2 w-4 h-4 font-bold text-xs rounded-full border border-red-500 text-red-500">
                        {batchIndex + 1}
                      </div>
                    </div>

                    <div className="flex flex-col gap-2 -mr-4">
                      {fieldValue.length > 1 && (
                        <Button
                          icon={faTrashCan}
                          buttonStyle="outlined"
                          roundedStyle={RoundedStyles.Full}
                          size="sm"
                          className="h-8 w-8"
                          onClick={() => removeBatch(batchIndex)}
                          title="Remove batch"
                        />
                      )}
                      {batchIndex === fieldValue.length - 1 && (
                        <Button
                          icon={faPlus}
                          buttonStyle="outlined"
                          roundedStyle={RoundedStyles.Full}
                          size="sm"
                          className="h-8 w-8"
                          onClick={addBatch}
                          title="Add batch"
                        />
                      )}
                    </div>
                  </div>
                )
              })
            }}
          </FieldArray>
        )}

        {!isSingleBodySiteSelection && (
          <>
            <DropdownField
              field={`configurationItem[${medIndex}].zone`}
              label="Zone"
              optionLabel="display"
              optionValue=""
              dataKey="code"
              options={bodyZonesCodes}
              handleChange={(e) => onChangeBodyZone(e.value)}
              validation={(value) => !(value as Coding)?.code && "Zone is required"}
            />
            <BodySitesAndDosageField
              field={`configurationItem[${medIndex}].bodySite`}
              modalTitle={cardTitle}
              dosages={requiresDoseConfiguration ? dosage?.code?.split("|") ?? [] : []}
              configurationItem={configurationItem[medIndex]}
              onUpdateDosage={handelUpdateDosage}
            />
          </>
        )}
      </div>
    </div>
  )
}

type Props = {
  medIndex: number
  expanded: boolean
  onClick(): void
  onChangeBodySite(bodySiteCode: string[]): void
  onChangeBodyZone(bodyZone: Coding): void
  isSingleBodySiteSelection: boolean
  configCodes: string[]
  bodySiteActionCode?: Coding
  handelUpdateDosage(newDosage: number[], batchSelected: string[]): void
}

export { MedConfigurationItem }
