import { faEye } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { codeableConceptAsString } from "fhir"
import { useFormikContext } from "formik"
import { Dropdown } from "primereact/dropdown"
import { FC } from "react"

import { useShowSignedUrlDocument } from "commons"
import { formatsByTypes } from "data"
import { formatDate } from "utils"

import { DRData } from "../../types"

const DRDropdown: FC<Props> = ({ options, onChangeDR }) => {
  const { showDocument: showResults } = useShowSignedUrlDocument()

  const {
    values: { selectedDR },
    setFieldValue,
  } = useFormikContext<{
    labData: {
      [x: string]: string
    }
    qrId: string
    selectedDR: DRData
  }>()

  const onChange = (item: DRData | undefined) => {
    setFieldValue("labData", { ...(item?.observations?.values ?? {}) })
    if (item?.dr) delete item.dr.id
    setFieldValue("selectedDR", item)
    onChangeDR(item)
  }

  const itemTemplate = (item: DRData) => {
    const obs = item ? Object.values(item?.observations?.data) : []

    return (
      <div className="flex justify-between items-center space-x-2">
        <div className="flex items-center gap-1">
          <span className="text-sm">
            {item?.dr?.issued ? formatDate(new Date(item?.dr?.issued), formatsByTypes.LONG_DATE) : "No results date"}
          </span>
          <span className="text-xs font-semibold italic">
            (
            {obs
              .slice(0, obs.length > 2 ? 2 : obs.length)
              .map((o) => codeableConceptAsString(o.code))
              .join(", ")}
            {obs.length > 2 && ` and ${obs.length - 2} more`})
          </span>
        </div>
        <div className="flex items-center gap-2">
          <span className="text-gray-500">
            {item?.dr?.performer?.[0]?.display ??
              (item?.observations?.data && Object.values(item?.observations?.data)?.[0]?.performer?.[0]?.display) ??
              "Unknow lab"}
          </span>
          {item?.dr?.presentedForm && (
            <FontAwesomeIcon
              icon={faEye}
              title="View Results"
              onClick={(e) => {
                e.stopPropagation()
                showResults(item?.dr?.presentedForm?.[0]?.url ?? "")
              }}
            />
          )}
        </div>
      </div>
    )
  }

  return (
    <div className="field relative flex flex-col">
      <label className="text-sm font-medium text-gray-700 mb-2">Labs results</label>
      <Dropdown
        options={options}
        optionLabel="dr.issued"
        itemTemplate={itemTemplate}
        valueTemplate={itemTemplate}
        onChange={(e) => onChange(e.value)}
        value={selectedDR}
        showClear={!!options.length}
        dataKey="dr.id"
      />
    </div>
  )
}

type Props = {
  options: DRData[]
  onChangeDR(selectedDR?: DRData): void
}

export { DRDropdown }
