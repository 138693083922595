import { BigNumber } from "bignumber.js"
import { format, parseISO } from "date-fns"
import { Quantity, humanNameAsString } from "fhir"
import { useFormikContext } from "formik"
import { useCallback } from "react"

import { formatsByTypes } from "data"
import { useMedicationsStatement } from "medications-statement"
import { usePatientContext } from "patients"
import { getQuantity } from "utils"
import { useVitals } from "vitals"

import { ProcedureData } from "../types"

const useProcedureNotePrefillData = (): PrefillData => {
  const {
    patient: { birthDate, name },
    patientId,
  } = usePatientContext()
  const { latestVitals } = useVitals(patientId)
  const { medications } = useMedicationsStatement(patientId)
  const {
    values: {
      procedure: { performer },
    },
  } = useFormikContext<ProcedureData>()

  const renderMedicationStatements = useCallback(() => {
    const medicationsArray =
      medications?.reduce((prev, { medication }) => {
        if (medication?.CodeableConcept?.text) {
          return [...prev, medication?.CodeableConcept?.text]
        }

        return prev
      }, [] as string[]) ?? []

    return medicationsArray.join("<br/><br/>")
  }, [medications])

  let bmi = "N/A"
  const height = (latestVitals?.height?.value as Quantity)?.value
  const weight = (latestVitals?.weight?.value as Quantity)?.value

  if (height && weight) {
    const [feets, inches] = height.toString().split(".")

    const heightInches = new BigNumber(feets).multipliedBy(12).plus(inches)

    bmi = new BigNumber(weight).multipliedBy(703).dividedBy(heightInches.multipliedBy(2)).toFixed(2)
  }

  return {
    patientName: humanNameAsString(name?.[0]),
    patientFirstName: name?.[0]?.given?.[0] ?? "",
    patientDOB: birthDate ? format(parseISO(birthDate), formatsByTypes.LONG_DATE) : "",
    currentDate: format(parseISO(new Date().toISOString()), formatsByTypes.LONG_DATE),
    practitionerName: performer?.[0]?.actor?.display ?? "",
    patientHeight: getQuantity(latestVitals?.height?.value as Quantity),
    patientWeight: getQuantity(latestVitals?.weight?.value as Quantity),
    patientBloodPressure: latestVitals?.bloodPressure?.value as string,
    patientPulse: getQuantity(latestVitals?.heartRate?.value as Quantity),
    patientRespiration: getQuantity(latestVitals?.respiratoryRate?.value as Quantity),
    patientTemperature: getQuantity(latestVitals?.temperature?.value as Quantity),
    medicationStatements: renderMedicationStatements(),
    bmi,
  }
}

type PrefillData = {
  patientName: string
  patientFirstName: string
  patientDOB: string
  patientHeight: string
  patientWeight: string
  patientBloodPressure: string
  patientPulse: string
  patientRespiration: string
  patientTemperature: string
  currentDate: string
  practitionerName: string
  medicationStatements: string
  bmi: string
}

export { useProcedureNotePrefillData }
